@import "../partitials/sidebar/sidebar";

$mainPoint: 1200px;
.slider{
  position:absolute;
  width:100%;
  height:5px;
  overflow-x: hidden;
}

.line{
  position:absolute;
  opacity: 0.4;
  background:#529e00;
  width:150%;
  height:5px;
}

.subline{
  position:absolute;
  background: #529e00;
  height:5px;
}
.inc{
  animation: increase 2s infinite;
}
.dec{
  animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
   from { left: -5%; width: 5%; }
   to { left: 130%; width: 100%;}
}
@keyframes decrease {
   from { left: -80%; width: 80%; }
   to { left: 110%; width: 10%;}
}
.wrapper {
  background: #f4f6f9;
  display: flex;
  height: 100vh;
  width: 100%;
}

.main-content-wrapper {
  flex: 1 1 0;
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
  padding-left: 35px;
  @media screen and (min-width: 1500px) {
    transform: translateX(0);
    padding-left: 0;
  }

  &.shrinked-for-sidebar {
    @media screen and (min-width: 1200px) {
      transform: translateX(265px);
    }
    @media screen and (min-width: 1500px) {
      transform: translateX(0);
    }
  }

  .page-wrapper {
    width: 100%;
    overflow-y: auto;
    flex: 1 1 0;
    padding: 20px 10px 0;
    @media screen and (min-width: 480px) {
      padding: 20px 25px 0;
    }
  }

  @media screen and (min-width: $mainPoint) {
    //display: block;
    overflow-y: auto;

    .page-title,
    .page-wrapper {
      &.wider {
        max-width: 1400px;
      }
      display: flex;
      flex-direction: column;
      overflow: visible;
      max-height: 100%;
      margin: {
        left: auto;
        right: auto;
      }
    }

  }
}

.page-title {
  font-size: 20px;
  position: relative;

  @media screen and (max-width: $mainPoint) {
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    padding: 16px 20px;
    text-align: center;
  }
}

.sidebar_toggler-box {
  position: absolute;
  right: 0;
  top: 40px;
  width: 40px;
  height: 40px;
  z-index: 500;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba($black, 0.25);
  background: white;
  text-align: center;
  @media screen and (min-width: 1500px) {
    display: none;
  }
  img {
    display: block;
    text-align: center;
    margin: 8px auto;
    width: 24px;
    height: 24px;
  }
}

/* print styles */
@media print {
  //reset styles for print
  .wrapper {
    background: initial!important;
    display: initial!important;
    height: initial!important;
    width: initial!important;

    .main-content-wrapper {
      margin: 0!important;
      padding: 0!important;
      flex: initial!important;
      max-height: initial!important;
      height: initial!important;
      display: initial!important;
      flex-direction: initial!important;
      overflow: initial!important;

      .page-wrapper {
        display: initial!important;
        flex-direction: initial!important;
        max-width: initial!important;
        overflow: initial!important;
        max-height: initial!important;
        margin: 0!important;
        padding: 0!important;
      }
    }
  }
  body {
    background: initial!important;
  }
}

@page {
  //reset styles for print
  .wrapper {
    background: initial!important;
    display: initial!important;
    height: initial!important;
    width: initial!important;

    .main-content-wrapper {
      margin: 0!important;
      padding: 0!important;
      flex: initial!important;
      max-height: initial!important;
      height: initial!important;
      display: initial!important;
      flex-direction: initial!important;
      overflow: initial!important;

      .page-wrapper {
        display: initial!important;
        flex-direction: initial!important;
        max-width: initial!important;
        overflow: initial!important;
        max-height: initial!important;
        margin: 0!important;
        padding: 0!important;
      }
    }
  }
  body {
    background: initial!important;
  }
}

.text-left {
  text-align: left;
}
