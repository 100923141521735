.access-item {
  display: flex;

  input {
    height: 15px;
    width: 15px;
  }
}

.voucher-code {
  border-bottom: 1px solid #80808045;
  padding-bottom: 10px;
}

.institution-name {
  font-weight: bold;
}

.checkbox-wrapper-2 {
  padding-right: 10px;
}
