$checkbox-bg: #dfe1e4;
$checkbox-hover-bg: #c9cbcd;
$checkbox-checked-bg: #529e00;
$checkbox-checked-hover-bg: #478500;
$checkbox-disabled-bg: $checkbox-bg;
$checkbox-size: 20px;
$checkbox-circle-size: 14px;

.checkbox-wrapper-2 {
  [type="checkbox"] {
    appearance: none;
    background-color: $checkbox-bg;
    border-radius: 72px;
    border-style: none;
    flex-shrink: 0;
    height: $checkbox-size;
    margin: 0;
    position: relative;
    width: 30px;
    cursor: default;

    &::before {
      bottom: -6px;
      content: "";
      left: -6px;
      position: absolute;
      right: -6px;
      top: -6px;
    }

    &,
    &::after {
      transition: all 100ms ease-out;
    }

    &::after {
      background-color: #fff;
      border-radius: 50%;
      content: "";
      height: $checkbox-circle-size;
      left: 3px;
      position: absolute;
      top: 3px;
      width: $checkbox-circle-size;
    }

    &:hover {
      background-color: $checkbox-hover-bg;
      transition-duration: 0s;
    }

    &:disabled:hover {
      background-color: $checkbox-disabled-bg;
      transition-duration: 0s;
    }

    &:checked {
      background-color: $checkbox-checked-bg;

      &:disabled {
        background-color: #808080;
      }

      &::after {
        background-color: #fff;
        left: 13px;
      }

      &:hover {
        background-color: $checkbox-checked-hover-bg;
      }

      &:disabled:hover {
        background-color: #808080;
      }
    }
  }

  :focus:not(.focus-visible) {
    outline: 0;
  }
}
