$outline-color: #767676;

button {
  border: none;
  box-shadow: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  font-weight: 600;
}

.g_btn {
  border-radius: 36px;
  font-size: 12px;
  vertical-align: middle;
  height: 36px;
  width: 136px;
  color: $white;
  transition: all 100ms ease-in-out;

  &:not([disabled]):hover {
    box-shadow: 0 0 7px 2px $primary-color;
  }

  &:not([disabled]):active {
    box-shadow: 0 0 3px 1px $primary-color;
    transform: scale(0.9);
  }

  &:not(:last-child) {
    margin-right: 14px;
  }

  &--primary {
    background: $primary-color;
  }

  &--disabled {
    background: #8a8686;
    cursor: not-allowed;
  }

  &--outlined {
    color: $outline-color;
    border: 1px solid;
  }

  &--section {
    color: $outline-color;
    border: 1px solid;
    min-height: 24px;
    min-width: 136px;
    margin: 6px 0;
    width: unset;
  }
}

.arrow-switcher-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  line-height: 0;

  &.arrow {
    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  .arrow-icon {
    width: 16px;
    height: 16px;

    &.right {
      transform: rotate(180deg);
    }
  }
}

//SORT ICON
.table_sort-icon {
  display: inline-block;
  transition: all ease-in 300ms;
  position: relative;
  width: 10px;
  height: 10px;
  margin-left: 5px;

  &:before,
  &:after {
    border: 4px solid transparent;
    content: '';
    display: block;
    height: 0;
    right: 0;
    top: 50%;
    position: absolute;
    width: 0;
  }

  &:before {
    border-bottom-color: #ababab;
    margin-top: -10px;
  }

  &:after {
    border-top-color: #ababab;
    margin-top: 1px;
  }

  &--ascending {
    &:before {
      margin-top: -5px;
      border-bottom-color: #ababab !important;
    }

    &:after {
      border-top-color: transparent !important;
    }
  }

  &--descending {
    &:before {
      border-bottom-color: transparent !important;
    }

    &:after {
      border-top-color: #ababab !important;
    }
  }
}

.edit-action-block {
  .comment_edit_btn {
    height: 20px;
    width: 20px;
    fill: $text-color_additional
  }
}

.optional-button {
  flex: 0 0 auto;
  border: 1px solid $primary-color;
  padding: 3px 6px;
  margin: 0 12px 12px 0;
  border-radius: 4px;

  span {
    margin-right: 6px;
  }

  svg {
    height: 12px;
    width: 12px;
    cursor: pointer;
  }
}

.side-note-wrapper {
  padding-right: 25px;

  .edit-action-block {
    right: 0;
  }
}

.loginCodeUpdateBtn {
  padding: 0px;
  margin: 0px;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
  align-self: left;
  align-content: left;
}

.toolButton:hover {
  background-color: $primary-color;
  color: white;
}

.toolButton:active {
  background-color: $primary-color;
  color: white;
  font-size: 103%;
}

.feature-blocked {
  position: relative;
  cursor: not-allowed !important;

  &::after {
    content: '';
    position: absolute;
    bottom: -15px;
    right: 0;
    width: 30px;
    height: 30px;
    background-color: white;
    border: 1px solid #8a8686;
    border-radius: 50%;
    background-image: url("../../img/icons/feature-blocked.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
