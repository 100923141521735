$border: 1px solid #E3E3E3;


.test_general-info_box {
  padding: 20px 0;
  border-bottom: $border;
  .info-body {
    font-size: 14px;
    color: #424242;
  }
}

.test_coments_box {
  border-bottom: $border;
  margin-bottom: 20px;
  .card_comment-box { padding-bottom: 10px; }
}

.preliminary-results_box {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: $border;
  .courseChart {
    height: 92px;
    width: 94px;
  }
}

.course-сhart_image {
  height: 70px;
  width: 70px;
  @media (min-width: 767px) {
    margin-top: 10px;
  }
}


.dementia-duration_box {
  font-size: 14px;
  width: 200px;
}
.dementia_sections-list,
.dementia_values-list {
  display: flex;
  width: 100%;
  li {
    flex: 1 1 0px;
    font-size: 13px;
    text-align: center;
  }

}
.dementia_sections-list {
  height: 16px;
  border-radius: 12px;
  border: $border;
  margin-bottom: 8px;
  overflow: hidden;
  li {
    height: 100%;
    background: #f44336;
    &:not(:last-child) {
      border-right: 2px solid #000;
    }
    &:not(.active) { background: transparent; }

  }
}

.flex-container.disfunctions_analize,
.flex-container.disfunctions_legend { margin-bottom: 40px; }


.brain_map_box {
  display: flex;
  justify-content: center;
}
.brain_layer {
  position: relative;
  &--top {
    .brain_points-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      left: 22px;
      top: 50%;
      transform: translateY(-50%);
      width: 85%;
    }
  }
  &--profil {
    width: 100%;
    height: 100%;
    .additional-wrapper {
      position: relative;
      padding: 0 0 61.7160804%;
    }
    .brain_points-list {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }
  .BrainProfil {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.brain_point-item {
  position: absolute;
  height: 32px;
  width: 32px;
  flex: 0 0 32px;
  border: 1px solid #fff;
  border-radius: 50%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  transition-duration: 1s;
  cursor: pointer;
  &.color-0 { background: #fff; }
  &.color-1 { background: #92DE50; }
  &.color-2 { background: #FFE600; }
  &.color-3 { background: #FA5001; }

  &.color-white { background: #fff; }
  &.color-green { background: #92DE50; }
  &.color-yellow { background: #FFE600; }
  &.color-red { background: #FA5001; }

  &.color-white-old { background: #fff; }
  &.color-green-old { background: #6ebe3e; }
  &.color-yellow-old { background: #f0831f; }
  &.color-red-old { background: #ef3c3d; }
  &:after {
    content: attr(data-content);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3D(-50%, -115%, 0);
    min-width: 100px;
    padding: 5px;
    color: #fff;
    background: #000;
    font-size: 12px;
    font-weight: 600;
    display: none;
    text-align: center;
    z-index: 10;
  }
  &:hover {
    height: 42px;
    width: 42px;
    box-shadow: 0px 1px 20px rgba(255, 255, 255, 0.6);
    transition-duration: 0.5s;
    z-index:1;
    &:after { display: block; }
  }
  > span {
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    color: #000;
  }
}

.disfunctions_side_box {
  border: $border;
  border-radius: 5px;
  min-height: 100%;
}

.disfunctions_side_head,
.disfunctions_side_body {
  padding: 18px 14px;
}
.disfunctions_side_head {
  font-size: 14px;
  border-bottom: $border;
  > p {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.4;
    color: #000;
  }
  .side_title {
    font-size: 10px;
    font-weight: 600;
    color: #455A64;
    text-transform: uppercase;
  }
  .selector_list-block {
    max-height: 210px;
    right: 0;
    left: auto;
  }
  .side_selector {
    .title {
      font-size: 10px;
      text-transform: uppercase;
      margin-bottom: 8px;
      color: #4A4A4A;
    }
  }
}

.disfunctions_legend {
  .legend-item {
    max-width: 130px;
    padding-left: 20px;
    position: relative;
    margin: 20px 10px;
    &:before {
      content: '';
      height: 10px;
      width: 10px;
      position: absolute;
      left: 0;
      top: 2px;
      border-radius: 50%;
      border: 2px solid;
    }
    &.red {
      color: #FA5001;
      &:before { background: #FA5001; }
    }
    &.yellow {
      color: #FFE500                  ;
      &:before { background: #FFE500; }
    }
    &.green {
      color: #92DE50;;
      &:before { background: #92DE50;; }
    }
    &.white { color: #B2B2B2; }
    span {
      display: block;
      color: #424242;
      font-size: 10px;
      line-height: 14px;
    }
  }
}

.marker-color-green-old {
  &::before {
    background: #6ebe3e;
  }

  color: #6ebe3e;
}

.marker-color-yellow-old {
  &::before {
    background: #f0831f;
  }

  color: #f0831f;
}

.marker-color-red-old {
  &::before {
    background: #ef3c3d;
  }

  color: #ef3c3d;
}

.brain_graph_analize {
  h3 {
    color: #424242;
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 20px;
  }
  .brain-zone_color {
    padding: 4px 6px;
    margin-right: 6px;
    border: 1px solid #B2B2B2;
    color: #000;
    font-size: 10px;
    &.color-0 {
      background-color: #ffffff;
    }
    &.color-1 {
      background-color: #92DE50;
    }
    &.color-2 {
      background-color: #FFE500;
    }
    &.color-3 {
      background-color: #FA5001;
    }
    &.color-white {
      background-color: #ffffff;
    }
    &.color-green {
      background-color: #92DE50;
    }
    &.color-yellow {
      background-color: #FFE500;
    }
    &.color-red {
      background-color: #FA5001;
    }
    &.color-white-old { background: #fff; }
    &.color-green-old { background: #6ebe3e; }
    &.color-yellow-old { background: #f0831f; }
    &.color-red-old { background: #ef3c3d; }
  }
  li {
    display: flex;
    align-items: center;
    color: #424242;
    font-size: 14px;
    margin-bottom: 12px;
  }
}

.disfunctions_side_patterns {
  .brain-holder {
    text-align: center;
    position: relative;
    &:not(:last-child) { margin-bottom: 8px; }
    img {
      width: 80%;
      height: 80%;
    }
    .patterns_layer {
      position: absolute;
      left: 0;
      top: 0;
      height:100%;
      width:80%;
      margin-left:10%;
    }
  }
  .brain-category {
    &.frontal-pt {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
    margin: 0 0 16px;

  }
}

.card-header {

  &-results-header {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    @media (min-width: 767px) {
      height: 42px;
    }
  }

  &-duration_box {
    width: 240px;
  }

  &-results-block {
    display: flex;
    flex-direction: column;
    flex-basis: auto;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    @media (min-width: 767px) {
      align-items: flex-start;
      padding-top: 0;
      padding-bottom: 0;
    }
    .common-title {
      padding-bottom: 8px;
      @media (min-width: 767px) {
        padding-bottom: 0;
      }
    }
  }
  &-results-block:nth-child(n+2) {
    @media (min-width: 767px) {
      padding-left: 50px;
    }
  }

  &-results {
    justify-content: center;
    flex-direction: column;
    @media (min-width: 767px) {
      justify-content: space-between;
      flex-direction: row;
    }
    @media (min-width: 991px) {
      padding-left: 60px;
      justify-content: flex-start;
    }
  }

}
