@import "../../../../assets/scss/utils/colors";

.medical-test-item {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $ordinary-grey;
  height: 40px;
  border-radius: 40px;
  font-size: 14px;
  color: $white;
  background-color: $ordinary-grey;
  transition: all 200ms ease-in;
  cursor: pointer;
  font-weight: 600;
  padding: 0 10px;
  text-align: center;

  &:hover {
    background-color: $bg-color_primary;
    color: $light-grey;
  }

  &__wrapper {
    padding-bottom: 10px;
  }

  &--selected {
    position: relative;
    background-color: $success-color;
    border-color: $success-color;

    &:hover {
      background-color: $thermometer-color-green;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -10px;
      right: 0;
      width: 25px;
      height: 25px;
      background-color: white;
      border: 1px solid $success-color;
      border-radius: 50%;
      background-image: url("../../../../assets/img/icons/check-mark-success.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 70% 70%;
    }
  }
}

.sub-items * {
  font-size: 12px;
  margin-left: 10px;
  height: 40px;
}
